

























































































import { API_SYSTEM_LOG_LIST, API_USER_LIST } from "@/configs/Apis";
import formatters from "@/utils/ColumnFormater";
import { isEmpty } from "@/utils/Common";
import { Fetcher } from "@/utils/Request";
import DList from "@/views/base/DList.vue";
import moment, { Moment } from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DContainerLayout from "../base/DContainerLayout.vue";
@Component({
  components: { DList, DContainerLayout }
})
export default class SystemLogList extends Vue {
  @Prop({ type: String, default: () => "" })
  nodeQueryData!: string;

  queryStr = "";
  queryData: any = { logDateOption: "all" };
  showSearch = false;
  users: Array<any> = [];
  loadingUser = false;
  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }

  get dataApi() {
    return API_SYSTEM_LOG_LIST;
  }

  queryUser(query: string) {
    if (query !== "") {
      this.loadingUser = true;
      setTimeout(() => {
        this.loadingUser = false;
        Fetcher.queryData(API_USER_LIST, { keyword: query }, "POST")
          .then(rs => {
            this.users = rs.data;
          })
          .catch(() => "");
      }, 200);
    } else {
      this.users = [];
    }
  }

  get colFormatters() {
    return formatters;
  }

  createTableColumns() {
    const columns: Array<TableColumnOption> = [
      {
        prop: "operationIp",
        display: "操作ip",
        width: 240,
        formatter: (data: any, column: any, value: any) => {
          return `${data.operationIp}(${data.operationCity})`;
        }
      },
      {
        prop: "userId",
        display: "操作者",
        width: 280,
        formatter: (data: any, column: any, value: any) => {
          if (data.userId === -1) {
            return "<span class=\"bguser\">系统后台任务</span>";
          }
          return data.user?.name ?? "";
        }
      },
      {
        prop: "user.enterpriseName",
        display: "所属单位",
        sortable: false,
        width: 280,
        formatter: (data: any) => {
          return data.user?.enterpriseName ?? "";
        }
      },
      { prop: "operationTime", display: "操作时间", width: 160 },
      {
        prop: "operationType",
        display: "日志类型",
        width: 160,
        formatter: formatters.formatOperationType.bind(this)
      },
      { prop: "title", display: "标题", width: 300 },
      { prop: "content", display: "详情" }
    ];
    this.$store.dispatch("systemlog/setColumns", columns);
  }

  onLogDateChange(arrs: Array<string>) {
    if (arrs && arrs.length) {
      this.queryData.startDate = arrs[0];
      this.queryData.endDate = arrs[1];
    } else {
      (this.queryData.startDate as any) = undefined;
      (this.queryData.endDate as any) = undefined;
    }
    this.onSearchConditionChange();
  }

  @Watch("queryData.operationType")
  @Watch("queryData.createdUserId")
  onSearchConditionChange() {
    const fields = ["startDate", "endDate", "operationType", "createdUserId"];
    const params: { [key: string]: any } = {};
    fields.forEach((field: string) => {
      params[field] = (this.queryData as any)[field];
    });
    if (isEmpty(params.createdUserId)) {
      delete params.createdUserId;
    }
    this.queryStr = JSON.stringify(params);
  }

  @Watch("queryData.logDateOption")
  onRemindDateOptionChange() {
    let now: Moment | null = moment();
    let startDate: Moment | null = moment();
    switch (this.queryData.logDateOption) {
      case "oneweek":
        startDate = startDate.add(-7, "days");
        break;
      case "onemonth":
        startDate = startDate.add(-1, "months");
        break;
      case "threemonth":
        startDate = startDate.add(-3, "months");
        break;
      default:
        now = null;
        startDate = null;
        break;
    }
    if (!isEmpty(now)) {
      this.queryData.endDate = now!.format("YYYY-MM-DD");
    } else {
      (this.queryData.endDate as any) = undefined;
    }
    if (!isEmpty(startDate)) {
      this.queryData.startDate = startDate!.format("YYYY-MM-DD");
    } else {
      (this.queryData.startDate as any) = undefined;
    }
    if (this.queryData.logDateOption !== "custom") {
      this.onSearchConditionChange();
    }
    if (
      this.queryData.logDateOption !== "custom" &&
      this.queryData.hasOwnProperty("logDate")
    ) {
      this.queryData.logDate = null;
    }
  }

  doSearch() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }
}
